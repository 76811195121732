<template>
  <div>
    <v-card class="rounded-lg mt-6 mb-2 px-12 pb-12 pt-8 card-shadow">
      <v-form ref="form" @submit.prevent="submitPaywayForm">
        <div
        class="heading mb-3">
          How would you like to pay?
        </div>
        <v-card
          outlined
          height="100"
          class="mt-10 mb-6 py-2"
          v-on:click="payNow">
          <v-card-title
            flat
            class="icon-card pay-button-text justify-center">
            <v-icon
              class="mr-2"
              :color="product.ColourPrimary">
                {{payIcon}}
            </v-icon>
            Pay now
          </v-card-title>
          <v-card-subtitle>
            {{accountDetails}}
          </v-card-subtitle>
        </v-card>
        <v-card
          outlined
          height="100"
          class="py-5"
          v-on:click="newCard">
          <v-card-title
            flat
            class="icon-card pay-button-text align-center justify-center">
            <v-icon
              class="mr-2"
              :color="product.ColourPrimary">
                mdi-plus-circle
            </v-icon>
            {{this.paywayDetails.creditCard ? 'New Card Details' : 'New Account Details'}}
          </v-card-title>
        </v-card>
      </v-form>
    </v-card>
    <v-btn
        class="mt-6 mb-2 text-none"
        text
        :color="product.ColourPrimary"
        :href="sessionData.returnURLToWebsite">
        <v-icon class="mr-1">
          mdi-arrow-left
        </v-icon>
        Return to Payment Setup
    </v-btn>
    <v-overlay :value="fetching">
      <v-progress-circular
      indeterminate
      size="64">
    </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { formatCurrency } from '../common/utils.js';
import { paymentTypes, paymentMethods } from '../common/constants';
import { mapState } from 'vuex';
import { apiPost } from '../common/utils.js';
import { ENV } from '../common/config';

export default {
    name: 'ChoosePaymentMethod',
    setup () {
        return { v$: useVuelidate() }
    },
    mounted: function () {},
    data() {
        return {
            fetching: false,
            paymentTypes,
            isInvalid: false,
            payButtonDisabled: true,
            creditCardFrame: null,
            bankAccountFrame: null,
            paymentMethods,
            formatCurrency
        };
    },
    computed: mapState({
        sessionData: state => state.sessionData.sessionData,
        product: state => state.sessionData.product,
        paywayDetails: state => state.sessionData.paywayDetails,
        sessionToken: state => state.sessionData.sessionToken,
        accountDetails (){
            console.log(this.paywayDetails);
            if(this.paywayDetails.creditCard){
                return `Card ending in ${this.paywayDetails.creditCard.cardNumber}`
            }
            return `Account ending in ${this.paywayDetails.bankAccount.accountNumber}`
        },
        payIcon() {
            if(this.paywayDetails.creditCard){
                return 'mdi-credit-card';
            }
            return 'mdi-bank';
        }
    }),
    methods: {
        payNow() {
            this.fetching = true;
            apiPost(`${ENV.PAYMENT_API_URL}/paymentapi-pay`, {
                type: 'payWithSessionID',
                request: {
                    sessionID: this.sessionToken,
                    paymentData: {
                        payWithExistingDetails: 'Y'
                    }
                }
            }).then((response) => {
                this.fetching = false;
                const isError = response.processMessages && !!response.processMessages.length;
                if (!isError) {
                    this.$emit('payment-successfull', response);
                } else {
                    this.$emit('payment-rejected', response);
                }
            }).catch(() => {
                this.fetching = false;
            });
        },
        newCard() {
            if(this.sessionData.type === 'paywithpayway'){
                        this.$router.push('/pay');
            }
            else if(this.sessionData.type === 'updatepayway'){
                this.$router.push('/update');
            }
        }
    }
}
</script>

<style scoped>
.heading {
  width: 100%;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5;
  text-align: left;
  color: #375258;
}

.pay-button-text {
  font-family: Open Sans;
  font-size: 1.15rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: rgba(0,0,0,.87);
  margin-bottom: 4px;
}

.icon-card {
  background: inherit;
}

button.v-btn.paybutton {
  text-transform: none !important;
  color: white !important;
  margin-top: 10px;
  font-weight: 600;
  font-size: 1rem;
}

.v-card:hover {
  border-color: #999;
}

@media screen and (max-width: 600px) {
  .v-application .mx-6 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  form.v-form {
    width: 70% !important;
    margin: 0 auto !important;
  }
  .v-application .mx-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}
</style>
