<template>
    <div>
        <v-checkbox
        v-model="termsAndConditions">
            <template v-slot:label>
                <div>
                I agree to the
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <a
                        @click = showModal
                        class="termsAndCondtionsLink"
                        v-on="on">
                        Direct Debit Request
                    </a>
                    </template>
                    Show Direct Debit Request
                </v-tooltip>
                </div>
            </template>
        </v-checkbox>
        <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        :persistent="true"
        v-model="dialog">
            <template>
                <v-card class="text-left">
                    <v-card-title class="text-h5 lighten-2">
                        Direct Debit Request
                    </v-card-title>

                    <v-card-text>
                      <div class="">
                        <h2>Our commitment to you</h2>
                        <p>This document outlines our service commitment to you, in respect to the Direct Debit Request (DDR) arrangements made between CrediBot Pty Ltd and you. It sets out your rights, our commitment to you and your responsibilities to us, together with where you go for assistance.</p>
                        <h3>Initial Terms of Agreement</h3>
                        <p>In the terms of the Direct Debit Request arrangements made between us and signed by you, we undertake to periodically direct debit your nominated account for the agreed amount for payment of your outstanding debt.</p>
                        <h3>Drawing Arrangements</h3>
                        <p>The first drawing under this agreement will be drawn on the nominated day. If any drawing falls due on a non-business day, it will be debited form your account the previous business day before the scheduled drawing date. We will give at least 3 days notice when changes to the initial terms of the arrangement are made. This notice will state the new amount, dates, frequency and any other changes to the initial terms.</p>
                        <h2>Your Rights</h2>
                        <h3>Changes to the Arrangement</h3>
                        <p>If you want to make changes to the drawing arrangements, contact us directly by phone on 1300 665 274. These changes may include:</p>
                        <ul>
                          <li>deferring the drawing; or</li>
                          <li>altering the schedule; or</li>
                          <li>stopping an individual debit; or</li>
                          <li>suspending the DDR; or</li>
                          <li>cancelling the DDR Agreement</li>
                        </ul>
                        <br/>
                        <h3>Enquiries</h3>
                        <p>Direct all enquiries to us rather than your financial institution, there should be at least 3 working days prior to the next scheduled drawing date. All communication addressed to us should include you full name and customer reference number.</p>
                        <p>All personal customer information held by us will be kept confidential except that information provided to our financial institution to initiate the drawing from your nominated account.</p>
                        <h3>Disputes</h3>
                        <p>If you believe that a drawing has been initiated incorrectly, we encourage you to take the matter up directly with us by contacting 1300 665 274. If you do not receive a satisfactory response from us to your dispute, contact your financial institution who will respond to you with an answer to your claim.</p>
                        <ul>
                          <li>within 7 business days (for claims lodged within 12 months of disputed drawing); or</li>
                          <li>within 30 business days (for claims lodged more than 12 months of disputed drawing)</li>
                        </ul>
                        <p>You will receive a refund of the drawn amount if we can not substantiate the reason for the drawing.</p>
                        <p><strong>Note:</strong> Your financial institution will ask you to contact us to resolve your disputed drawing prior to you contacting us.</p>
                        <h2>Your commitment to us</h2>
                        <ul>
                          <li>your nominated account can accept direct debits (your financial institution can confirm this); and</li>
                          <li>that on the drawing date there is sufficient cleared funds in the nominated account; and</li>
                          <li>that you advise us if the nominated account is transferred or closed.</li>
                        </ul>
                        <p>If your drawing is returned or dishonoured by your financial institution you will be notified by mail, and your next scheduled drawing will take place as agreed. Any drawing that is returned or dishonoured will incur a $10 administration fee payable to CrediBot and you may be liable for other related fees that you may incur from your financial institution.</p>
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false">
                        Close
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import { paymentMethods } from '../common/constants';
export default {
    name: 'PaymentAgreement',
    data() {
        return {
            termsAndConditions: false,
            dialog: false,
            paymentMethods
        };
    },
    watch: {
        termsAndConditions(newValue){
            this.$emit("termsAndConditionChecked", newValue);
        }
    },
    computed: mapState({
        sessionData: state => state.sessionData.sessionData,
        product: state => state.sessionData.product
    }),
    methods: {
        showModal(event) {
            this.dialog = true;
            event.stopPropagation();
        }
    }
}
</script>
<style>
.termsAndCondtionsLink{
    text-decoration: none;
}
</style>
