export const paymentTypes =  {
    PAY_FULL: 1,
    PAYMENT_ARRANGEMENT: 2,
    PAY_PARTIAL: 3
};

export const paymentMethods = {
    CREDIT_CARD: 2,
    DIRECT_DEBIT: 3
};

export const frequencyCodes = {
    '1' : 'One Off',
    '2' : 'Weekly',
    '3' : 'Fortnightly',
    '4' : 'Monthly'
};

export const processMessagesPay = {
    InvalidCardDetails: 4207,
    PaymentPlanExists: 4211,
    BSBDoesNotExist: 4215,
    SessionTokenExpired: 4223,
	InvalidCardType: 4218
};

export const processMessagesGetSessionData = {
    SessionTokenExpired: 4103,
    SessionTokenInvaild: 4104
};