import Vue from 'vue'
import Vuex from 'vuex'
import sessionData from './models/sessionData';
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        sessionData
    },
    strict: true,
    getters: {
        sessionDataLoaded: state => state.sessionData.status,
		paywayDetails: state => state.sessionData.paywayDetails
    },
    plugins: []
});