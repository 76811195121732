function constructENV() {
    if(window.location.href.indexOf('checkout.credibot.com.au') !== -1) {
        //production
        return {
            PAYMENT_API_URL: 'https://api-pay.credibot.com.au'
        };
    }
    else if(window.location.href.indexOf('checkout-staging.credibot.com.au') !== -1) {
        //staging
        return {
            PAYMENT_API_URL: 'https://api-pay-staging.credibot.com.au'
        };
    }
    //local
    return {
        PAYMENT_API_URL: 'http://localhost:7071/api'
    };
}
export const ENV = constructENV();
