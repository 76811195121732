<template>
    <div>
      <v-btn
        text
        @click="privacyDialog = true">
        Privacy Policy
      </v-btn>
        <v-dialog
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="privacyDialog">
            <template>
                <v-card class="text-left">
                    <v-card-title class="text-h5 lighten-2">
                        Privacy Policy
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="privacyDialog = false"
                          icon
                          text>
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div class="">
                        <p>Credibot Pty Ltd (“we”, “us”, “our” “Credibot”) respects your right to privacy and we are committed to safeguarding the privacy of our users and application users.</p>
                        <p>The Privacy Act 1988 (Cth) (“the Privacy Act”) governs the way in which we must manage your personal information. This policy sets out how we collect, use, disclose and otherwise manage your personal information.</p>
                        <p>By accessing our website or the Credibot platform, or using any service we provide, you agree to the collection, use and disclosure of your personal information in the manner described in this policy.</p>
                        <p>We may amend or update this policy at any time.</p>
                        <h3>Personal Information</h3>
                        <p>The type of information which will collect from or about you will depend on how you use the services by us and which products or services you use. We do not collect personal information unless it is reasonably necessary for, or directly related to, one or more of the services or products we provide or the functions we carry out.</p>
                        <p>The types of personal information we may collect from you include (but are not limited to) your name, date of birth, gender, contact information, bank account and credit account details, or business name and ABN.</p>
                        <h3>Collection of Personal Information</h3>
                        <p>The main way we collect personal information about you is when you give it to us, for example when you input it, complete a form or contact us for information. We may also collect your personal information directly from other people or organisations.</p>
                        <p>The purpose for which we collect personal information is to provide and improve our services and products to you.</p>
                        <p>You may choose not to provide us with personal information. However, we may not be able to provide services or products to you without certain types of personal information.</p>
                        <p>We may also collect information from you electronically through internet browsing, mobile or tablet applications. Each time you use our application, we may collect information about your use of the application. We may use technology called ‘cookies’ when you use our application. Cookies can record information about your use of our application. You can choose to reject cookies by changing your browser settings. However, this may impact on your use of our application.</p>
                        <h3>Use of your personal information</h3>
                        <p>Notwithstanding anything else in this policy, where we collect your personal information in connection services we provide to your lender or credit provider (such as providing a payment facility to you to repay your loan) we will only use and disclose your personal information for the purposes of providing services to them.</p>
                        <p>We may use your personal information to carry out our business activities, including (without limitation):</p>
                        <ul>
                          <li>providing products and services relating to our business to you;</li>
                          <li>billing and debt recovery functions;</li>
                          <li>to respond to an enquiry or provide assistance that you request from us;</li>
                          <li>analysing our services and customer needs with a view to improving those services;</li>
                          <li>for the purposes of feedback, reviews and rates;</li>
                          <li>assist in the management of the products and services we provide; and</li>
                          <li>facilitating our internal business operations, including the fulfilment of any legal requirements.</li>
                        </ul>
                        <p>In relation to your personal information, we must:</p>
                        <ul>
                          <li>not do or omit to do anything which would cause us to contravene the Privacy Act;</li>
                          <li>collect, use, disclose, hold and destroy or de-identify it in accordance with the Privacy Act, including taking reasonable steps to ensure it is protected against misuse, interference, or loss and any unauthorised access, use, modification or disclosure.</li>
                        </ul>
                        <p>In order to carry out the activities described above we may disclose your personal information to:</p>
                        <ul>
                          <li>any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors;</li>
                          <li>outsourced service providers who assist us in providing any of the services, including telecommunications carriers and technology service providers;</li>
                          <li>organisations involved in maintaining, reviewing, upgrading and developing our technology and business systems; and</li>
                          <li>organisations involved in payments systems including financial institutions, merchants and payment organisations.</li>
                        </ul>
                        <br/>
                        <p>We may disclose your personal information as required or authorised by law or when you have given your consent to do so.</p>
                        <p>Your personal information will not be disclosed to anyone or accessible by anyone outside of Australia without your prior consent.</p>
                        <h3>Storage of Personal Information</h3>
                        <p>We take reasonable physical and electronic security measures to ensure that your personal information is stored safely to protect it from misuse, loss, unauthorised access, modification or disclosure.</p>
                        <p>We cannot ensure the security of any information that you transmit to us over the internet and you do so at your own risk.</p>
                        <p>We may use cloud storage to store the personal information we hold about you.</p>
                        <h3>Access to Personal Information</h3>
                        <p>You can request, at any time, for us to inform you of the personal information we hold about you. If any of the information we hold about you is inaccurate, out of date, incomplete or irrelevant, please contact us. We reserve the right to charge an administrative fee to provide the information we hold.</p>
                        <p>The Privacy Act sets out circumstances in which may refuse to give you access to the personal information such as if we consider the request to be frivolous or vexatious.</p>
                        <h3>Complaints and further information</h3>
                        <p>Should you wish to make a complaint about the management of your personal information or if you require further information regarding this Policy, please email us at <a href="mailto:privacy@credibot.com.au">privacy@credibot.com.au</a></p>
                        <p>We will endeavour respond to you within a reasonable time after receiving your complaint or enquiry.</p>
                        <p>For more information about privacy in general, you can visit the Australian Information Commissioner’s website at <a href="http://www.oaic.gov.au">www.oaic.gov.au</a> or call on <a href="tel:>1300 363 992">1300 363 992</a>.</p>
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="privacyDialog = false">
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: 'Privacy',
    data() {
        return {
            privacyDialog: false,
        };
    },
}
</script>
<style>
</style>
