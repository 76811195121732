import axios from 'axios';
export function GetSessionTokenAndDebtIDOutOfURL(url) {
    const tokens = url.split('?');
    if (tokens.length < 2) return {};
    const token = tokens[1];
    const sessionToken = token.split('&')[0];
    return { sessionToken };
}

export function formatCurrency(amount) {
    return new Intl.NumberFormat('en-au', { style: 'currency', currency: 'AUD' }).format(amount);
}


export function apiPost(url, data, headers) {
    const promise = new Promise((resolve, reject) => {
        axios.post(url, data, {headers}).then((response) => {
            resolve(response.data);
        }).catch((error) => {
			console.log(error);
            if(error.response){
                resolve(error.response.data);
            }
            else{
                reject(error);
            }
        });
    });
    return promise;
}