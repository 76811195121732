<template>
    <div>
        <v-checkbox
        v-model="termsAndConditions">
            <template v-slot:label>
                <div>
                I agree to the
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <a
                        @click = showModal
                        class="termsAndCondtionsLink"
                        v-on="on">
                        Terms & Conditions
                    </a>
                    </template>
                    Show Terms & Conditions
                </v-tooltip>
                </div>
            </template>
        </v-checkbox>
        <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        :persistent="true"
        v-model="dialog">
            <template>
                <v-card class="text-left">
                    <v-card-title class="text-h5 lighten-2">
                        Terms & Conditions
                    </v-card-title>

                    <v-card-text>
                      <div
                        class=""
                        v-html="sessionData.paymentMethod === paymentMethods.CREDIT_CARD ? product.ccGatewayTC : product.ddGatewayTC">
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false">
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import { paymentMethods } from '../common/constants';
export default {
    name: 'TermsAndConditions',
    data() {
        return {
            termsAndConditions: false,
            dialog: false,
            paymentMethods
        };
    },
    watch: {
        termsAndConditions(newValue){
            this.$emit("termsAndConditionChecked", newValue);
        }
    },
    computed: mapState({
        sessionData: state => state.sessionData.sessionData,
        product: state => state.sessionData.product
    }),
    methods: {
        showModal(event) {
            this.dialog = true;
            event.stopPropagation();
        }
    }
}
</script>
<style>
.termsAndCondtionsLink{
    text-decoration: none;
}
</style>
