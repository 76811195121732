<template>
    <v-app>
        <v-main>
        <v-row class="justify-center mt-12">
            <v-alert
            outlined
            type="warning"
            prominent
            max-width="70%">
            Invalid session ID or your session has expired.
            </v-alert>
        </v-row>
        </v-main>
        <v-footer
        dark>
            <v-card
            class="flex"
            flat
            tile>
            <v-card-text class="py-2 white--text text-center">
                {{ new Date().getFullYear() }} — <strong>Equifax</strong>
            </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
import useVuelidate from '@vuelidate/core';
export default {
  name: 'SessiontokenExpired',
    setup () {
        return { v$: useVuelidate() }
    },
  data() {
    return {};
  }
}
</script>

<style scoped>
.theme--dark.v-card{
    background: #3a4752;
}
</style>
