<template>
    <div class="main-div">
        <div class="box d-flex">
            <CustomerSummary> </CustomerSummary>
            <Pay
                v-if="showPay"
                v-on:payment-successfull="paymentSuccessfull"
                v-on:payment-rejected ="paymentRejected">
            </Pay>
            <Update
                v-if="showUpdate"
                v-on:payment-successfull="paymentSuccessfull"
                v-on:payment-rejected ="paymentRejected">
            </Update>
        </div>
        <v-row v-if="!!sessionData">
            <payment-result
            v-on:hide-modal-paymentresult="hideModal"
            :paymentStatus="paymentStatus"
			:paymentFailureReason="paymentFailureReason"
            :paymentType="sessionData.paymentType"
            :paymentProcessed="openModal"></payment-result>
        </v-row>
        <v-overlay :value="fetching">
            <v-progress-circular
            indeterminate
            size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import Pay from './Pay.vue';
import CustomerSummary from './CustomerSummary.vue';
import Update from './Update.vue';
import PaymentResult from './PaymentResult.vue';
import { paymentTypes, paymentMethods, processMessagesPay, frequencyCodes} from '../common/constants.js';
import { mapState } from 'vuex';

export default {
    name: 'Home',
    components: {
        Pay,
        PaymentResult,
        CustomerSummary,
        Update
    },
    data() {
        return {
            frequencyCodes,
            paymentTypes: paymentTypes,
            paymentMethods: paymentMethods,
            paymentStatus: null,
			paymentFailureReason: '',
            fetching: false,
            paymentProcessed: false,
            openModal: false
        };
    },
    computed: mapState({
        sessionData: state => state.sessionData.sessionData,
        product: state => state.sessionData.product,
        paywayDetails: state => state.sessionData.paywayDetails,
		sessionToken: state => state.sessionData.sessionToken,
        debtor: state => state.sessionData.debtor,
        showPay() { 
            return !!this.sessionData && this.sessionData.type === 'paywithpayway';
        },
        showUpdate() { 
            return !!this.sessionData && this.sessionData.type === 'updatepayway';
        }
    }),
    methods: {
        paymentSuccessfull() {
            this.navigateToURLSuccess();
        },
        paymentRejected(response) {
			this.paymentFailureReason = '';
            const sessionTokenExpired = response.processMessages.filter((pm)=> {
                return pm.code === processMessagesPay.SessionTokenExpired;
            });
			const invalidCardType = response.processMessages.filter((pm)=> {
                return pm.code === processMessagesPay.InvalidCardType;
            });
            if (sessionTokenExpired.length) {
                if(this.sessionData.returnURLFailure) {
                    window.location.href = this.sessionData.returnURLFailure;
                    return;
                }
            }
			if (invalidCardType.length) {
				this.paymentFailureReason = 'ccusedfordd';
			}
			this.paymentStatus = 'fail';
            this.paymentProcessed = true;
            this.openModal = true;
        },
        hideModal() {
            this.openModal = false;
        },
        navigateToURLSuccess() {
            if(this.sessionData.returnURLSuccess){
                window.location.href = `${this.sessionData.returnURLSuccess}`;
            } else{
                window.location.href = "/paymentSuccessfull";
            }
        }
    }
}
</script>

<style>
.main-div {
    margin-bottom: 100px;
}

.box {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 499px) {
    .box {
        flex-direction: column;
        margin: 0 10px;
    }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
    .box {
        flex-direction: column;
        margin: 0 40px;
    }
}
</style>
