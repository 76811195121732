import 	{ apiPost } from '../../common/utils.js';
import 	{ ENV } from '../../common/config.js';
import 	{ GetSessionTokenAndDebtIDOutOfURL } from '../../common/utils.js';
import { processMessagesGetSessionData } from '../../common/constants.js';

const state = () => (
    { 
        product: undefined,
        sessionData: undefined,
        debtor: undefined,
		paywayDetails: undefined,
        sessionToken: '',
        status: ''
    }
);
const getters = {
};
const actions = {
    getSessionData({commit}){
        const url = `${ENV.PAYMENT_API_URL}/paymentapi-getpaymentsessiondata`;
        let { sessionToken } = GetSessionTokenAndDebtIDOutOfURL(window.location.href);
        if (!sessionToken) {
            sessionToken = localStorage.getItem('sessionToken');
        }
        commit('setSessionToken', sessionToken);
        return apiPost(url, {
            request: {
                sessionToken
            },
        }).then((response)=>{
            if (response.processMessages) {
                const sessionTokenExpired = response.processMessages.filter((pm)=> {
                    return pm.code === processMessagesGetSessionData.SessionTokenExpired;
                });
                const invalidSessionToken = response.processMessages.filter((pm)=> {
                    return pm.code === processMessagesGetSessionData.SessionTokenInvaild;
                });
                if (sessionTokenExpired.length) {
                    commit('setStatus', 'tokenExpired');
                    return;
                }
                if (invalidSessionToken.length) {
                    commit('setStatus', 'tokenExpired');
                    return;
                }
            }
            commit('setProduct', response.response.product);
            commit('setSessionData', response.response.sessionData);
            commit('setDebtor', response.response.debtor);
			commit('setPaywayDetails', response.response.paywayDetails);
            commit('setStatus', 'success');
        }).catch(()=>{
			commit('setStatus', 'error');
		});
    }
}
const mutations = {
    setProduct(state, product){
        state.product = product;
    },
    setSessionData(state, sessionData){
        state.sessionData = sessionData;
    },
    setStatus(state, status){
        state.status = status;
    },
    setDebtor(state, debtor){
        state.debtor = debtor;
    },
    setSessionToken(state, sessionToken){
        localStorage.setItem('sessionToken', sessionToken);
        state.sessionToken = sessionToken;
    },
	setPaywayDetails(state, paywayDetails){
		state.paywayDetails = paywayDetails;
	}
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}