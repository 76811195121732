<template>
    <v-app>
        <v-container :fluid="true">
            <v-img
                max-height="150"
                max-width="100"
                position="absolute"
                src="@/assets/equifax.png">
            </v-img>
        </v-container>
        <v-main>
            <div>
                <v-alert
                outlined
                type="success"
                prominent
                border="left"
                >
                    Payment was successfull. You can close this window now
                </v-alert>
            </div>
        </v-main>
        <v-footer
        dark>
            <v-card
            class="flex"
            flat
            tile>
            <v-card-text class="py-2 white--text text-center">
                {{ new Date().getFullYear() }} — <strong>Equifax</strong>
            </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PaymentSuccessful',
  computed: mapState({
    sessionData: state => state.sessionData.sessionData,
    product: state => state.sessionData.product
  }),
}
</script>

<style scoped>
.theme--dark.v-card{
    background: #3a4752;
}
</style>
