import Vue from 'vue'
import App from './App.vue';
import PayRoute from './components/PayRoute.vue';
import ChooseMethodRoute from './components/ChooseMethodRoute.vue';
import PaymentSuccessful from './components/PaymentSuccessfull.vue';
import SessiontokenExpired from './components/SessiontokenExpired.vue';
import ServerError from './components/ServerError.vue';
import Loading from './components/Loading.vue';
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router';
import storeVuex from './store';
import moment from 'moment';

Vue.config.productionTip = false
Vue.use(VueRouter);
moment.locale('en-au');
const requireSessionData = (to, from, next) => {
    const sessionDataLoaded = storeVuex.getters.sessionDataLoaded === 'success';
    if(!sessionDataLoaded) next({name: 'LoadingRoute'});
    else next();
}

const requirePaywayDetails = (to, from, next) => {
    const sessionDataLoaded = storeVuex.getters.sessionDataLoaded === 'success';
    if(!sessionDataLoaded) next({name: 'LoadingRoute'});
    const paywayDetails = storeVuex.getters.paywayDetails;
    if(!paywayDetails) next({name: 'LoadingRoute'});
    else next();
}

const requireErrorStatus = (to, from, next) => {
    const hasError = storeVuex.getters.sessionDataLoaded === 'error' || storeVuex.getters.sessionDataLoaded === 'tokenExpired';
    if(!hasError) next({name: 'LoadingRoute'});
    else next();
}

const routes = [
    { path: '/', component: Loading, name: 'LoadingRoute' },
    { path: '/pay', component: PayRoute, name: 'PayRoute', beforeEnter: requireSessionData },
    { path: '/update', component: PayRoute, name: 'UpdateRoute', beforeEnter: requireSessionData },
	{ path: '/choosePayMethod', component: ChooseMethodRoute, name: 'ChoosePayMethod', beforeEnter: requirePaywayDetails },
    { path: '/tokenExpired', component: SessiontokenExpired, name: 'TokenExpired', beforeEnter: requireErrorStatus },
    { path: '/tokenInvalid', component: SessiontokenExpired, name: 'TokenInvalid', beforeEnter: requireErrorStatus },
	{ path: '/serverError', component: ServerError, name: 'ServerError', beforeEnter: requireErrorStatus },
    { path: '/success', component: PaymentSuccessful, name: 'PaymentSuccess', beforeEnter: requireSessionData },
];
const router = new VueRouter({
    routes,
    mode: 'history'
});


new Vue({
  vuetify,
  router,
  store: storeVuex,
  el: '#app',
  render: (h) => h(App)
});
