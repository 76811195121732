<template>
    <v-app>
        <v-container :fluid="true">
        </v-container>
        <v-main>
            <router-view> </router-view>
        </v-main>
        <v-footer dark class="pa-6" absolute>
          <v-row>
            <div class="body-2 d-flex align-center">Provided by CrediBot Pty Ltd ACN 142 466 322</div>
            <v-spacer>
            </v-spacer>
            <Privacy></Privacy>
          </v-row>
        </v-footer>
    </v-app>
</template>

<script>
import { mapState } from 'vuex';
import Privacy from './components/Privacy.vue';

export default {
    name: 'App',
    components: {
      Privacy,
    },
    computed: mapState({
        product: (state) => {
            return state.sessionData.product;
        }
    })
}
</script>
<style scoped>
#app {
    font-family: Open Sans, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #202020;
    margin-top: 0px;
    background-color: #F5F8F9;
}
</style>
