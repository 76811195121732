<template>
    <v-overlay :value="fetching">
        <v-progress-circular
        indeterminate
        size="64"
        ></v-progress-circular>
    </v-overlay>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'Loading',
  data() {
    return {
        fetching: true
    };
  },
  created(){
    this.$store.dispatch('sessionData/getSessionData');
  },
  computed: mapState({
    status: state => state.sessionData.status,
    sessionData: state => state.sessionData.sessionData,
    paywayDetails: state => state.sessionData.paywayDetails
  }),
  watch: {
    status: {
        handler: function(value){
            switch(value){
                case 'success': {
                    if ((this.paywayDetails.creditCard || this.paywayDetails.bankAccount) &&
                        this.sessionData.type !== 'updatepayway') {
                        this.$router.push('/choosePayMethod');
                        return;
                    }
                    if(this.sessionData.type === 'paywithpayway'){
                        this.$router.push('/pay');
                    }
                    else if(this.sessionData.type === 'updatepayway'){
                        this.$router.push('/update');
                    }
                    break;
                }
                case 'tokenExpired': {
                    this.$router.push('/tokenExpired');
                    break;
                }
                case 'error': {
                    this.$router.push('/serverError');
                    break;
                }
            }
        }
    }
  }
}
</script>