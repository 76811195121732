<template>
    <div class="main-div">
        <div class="box d-flex">
            <CustomerSummary> </CustomerSummary>
            <ChoosePaymentMethod
                v-on:payment-successfull="paymentSuccessfull"
                v-on:payment-rejected ="paymentRejected">
            </ChoosePaymentMethod>
        </div>
        <v-row v-if="!!sessionData">
            <payment-result
            v-on:hide-modal-paymentresult="hideModal"
            :paymentStatus="paymentStatus"
            :paymentType="sessionData.paymentType"
            :paymentProcessed="openModal"></payment-result>
        </v-row>
    </div>
</template>

<script>
import CustomerSummary from './CustomerSummary.vue';
import ChoosePaymentMethod from './ChoosePaymentMethod.vue';
import PaymentResult from './PaymentResult.vue';
import { paymentTypes, paymentMethods, processMessagesPay, frequencyCodes} from '../common/constants.js';
import { mapState } from 'vuex';

export default {
    name: 'Home',
    components: {
        PaymentResult,
        CustomerSummary,
        ChoosePaymentMethod
    },
    data() {
        return {
            frequencyCodes,
            paymentTypes: paymentTypes,
            paymentMethods: paymentMethods,
            paymentStatus: null,
            fetching: false,
            paymentProcessed: false,
            openModal: false
        };
    },
    computed: mapState({
        sessionData: state => state.sessionData.sessionData,
        product: state => state.sessionData.product,
        paywayDetails: state => state.sessionData.paywayDetails,
        debtor: state => state.sessionData.debtor
    }),
    methods: {
        paymentSuccessfull() {
            this.navigateToURLSuccess();
        },
        paymentRejected(response) {
            const filteredProcessMessages = response.processMessages.filter((pm)=> {
                return pm.code === processMessagesPay.SessionTokenExpired;
            });
            if (filteredProcessMessages.length) {
                if(this.sessionData.returnURLFailure) {
                    window.location.href = this.sessionData.returnURLFailure;
                    return;
                }
            }
            this.paymentStatus = 'fail';
            this.paymentProcessed = true;
            this.openModal = true;
        },
        hideModal() {
            this.openModal = false;
        },
        navigateToURLSuccess() {
            if(this.sessionData.returnURLSuccess){
                window.location.href = `${this.sessionData.returnURLSuccess}`;
            } else{
                window.location.href = "/paymentSuccessfull";
            }
        }
    }
}
</script>

<style>
.main-div {
    margin-bottom: 100px;
}

.box {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 499px) {
    .box {
        flex-direction: column;
        margin: 0 10px;
    }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
    .box {
        flex-direction: column;
        margin: 0 40px;
    }
}
</style>
