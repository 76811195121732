<template>
    <v-app>
        <v-main>
        <v-row class="justify-center mt-12">
            <v-alert
            dense
            outlined
            type="error"
            >
                Could not connect to server.
            </v-alert>
        </v-row>
        <v-row class="justify-center mt-12">
            <p> The server could not be contacted. Please try again later.</p>
        </v-row>
        </v-main>
        <v-footer
        dark>
            <v-card
            class="flex"
            flat
            tile>
            <v-card-text class="py-2 white--text text-center">
                {{ new Date().getFullYear() }} — <strong>Equifax</strong>
            </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
import useVuelidate from '@vuelidate/core';
export default {
  name: 'ServerError',
    setup () {
        return { v$: useVuelidate() }
    },
  data() {
    return {};
  }
}
</script>

<style scoped>
.theme--dark.v-card{
    background: #3a4752;
}
</style>
