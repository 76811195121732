<template>
  <v-card class="rounded-lg mb-sm-10 mb-4 elevation-2">
    <div class="">
      <v-card-title class="font-weight-bold pb-0" dense>
        Your Payment Plan
      </v-card-title>
      <v-card-text v-if="items.length">
        You will make <b>{{repaymentTime}}</b> periodic payments,
        with the last payment on <b>{{items[items.length - 1].date.format('dddd, Do MMM YYYY')}}</b>
      </v-card-text>
    </div>
    <v-divider></v-divider>
    <v-list max-height="400" style="overflow-y: auto;">
      <div class="" v-for="(item, index) in items" :key="index">
        <v-list-item class="mt-0 pl-2" color="primary" style="width: 300px;" dense>
          <v-list-item-avatar class="mr-2">
            <v-icon  color="primary">
              mdi-cash-clock
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle v-if="index === 0">
              First Payment
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="index === (items.length - 1)">
              Last Payment
            </v-list-item-subtitle>
            <v-list-item-title >
              {{ moment(item.date).format('ddd, Do MMM YYYY') }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            ${{item.amount | numFormat('0,000.00')}}
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="index < (items.length - 1)"></v-divider>
      </div>
    </v-list>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PaymentPlanPreview',
  props: {
    repaymentValue: {
      type: Number,
      default: () => 1,
    },
    commencementDate: {
      type: String,
      default: () => '',
    },
    repaymentTime: {
      type: Number,
      default: () => 1,
    },
    debtValue: {
      type: Number,
      default: () => 1,
    },
    interval: {
      type: String,
      default: () => 'weeks',
    },
  },
  computed: {
    items() {
      const payments = [];
      let runningTally = 0;
      for (let i = 0; i < this.repaymentTime; i += 1) {
        if (i < (this.repaymentTime - 1)) {
          payments.push({
            date: moment(this.commencementDate).add(i, this.interval),
            amount: this.repaymentValue,
          });
          runningTally += this.repaymentValue;
        } else {
          payments.push({
            date: moment(this.commencementDate).add(i, this.interval),
            amount: (this.debtValue - runningTally),
          });
        }
      }

      return payments;
    },
  },
  data() {
    return {};
  },
  methods: {
    moment,
  },
  mounted() {},
};
</script>
